@media screen and (min-width: 320px){
    .counternumber-body h2{
        color: white;
        margin: 30px 0 30px 35px;
    }
    .counternumber-container{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .counternumber-numberstitle{
        width: 175px;
        height: 125px;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .counternumber-numberstitle-numbers{
        display: flex;
        flex-direction: row;
        white-space: pre;
    }
    .counternumber-numberstitle-numbers p,
    .counternumber-numberstitle-title{
        font-size: 1.2rem;
        font-weight: bold;
    }
}

@media screen and (min-width: 480px){
    .counternumber-body h2{
        color: white;
        margin: 50px 0 30px 70px;
    }
    .counternumber-container{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .counternumber-numberstitle{
        width: 220px;
        height: 140px;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .counternumber-numberstitle-numbers{
        display: flex;
        flex-direction: row;
        white-space: pre;
    }
    .counternumber-numberstitle-numbers p,
    .counternumber-numberstitle-title{
        font-size: 1.5rem;
        font-weight: bold;
    }
}

@media screen and (min-width: 768px){
    .counternumber-body h2{
        color: white;
        font-size: 2rem;
        margin: 60px 0 30px 170px;
    }
    .counternumber-container{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .counternumber-numberstitle{
        width: 350px;
        height: 250px;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .counternumber-numberstitle-numbers{
        display: flex;
        flex-direction: row;
        white-space: pre;
    }
    .counternumber-numberstitle-numbers p,
    .counternumber-numberstitle-title{
        font-size: 2rem;
        font-weight: bold;
    }
}

@media screen and (min-width: 1024px){
    .counternumber-body h2{
        color: white;
        font-size: 2.5rem;
        margin: 60px 0 30px 300px;
    }
    .counternumber-container{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .counternumber-numberstitle{
        width: 430px;
        height: 275px;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .counternumber-numberstitle-numbers{
        display: flex;
        flex-direction: row;
        white-space: pre;
    }
    .counternumber-numberstitle-numbers p,
    .counternumber-numberstitle-title{
        font-size: 2.5rem;
        font-weight: bold;
    }
}

@media screen and (min-width: 1200px){
    .counternumber-body h2{
        margin: 40px 0 30px 230px;
        font-size: 2rem;
    }
    .counternumber-container{
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .counternumber-numberstitle{
        width: 300px;
        height: 150px;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .counternumber-numberstitle-numbers{
        display: flex;
        flex-direction: row;
        white-space: pre;
    }
    .counternumber-numberstitle-numbers p,
    .counternumber-numberstitle-title{
        font-size: 1.6rem;
        font-weight: bold;
    }
}

@media screen and (min-width: 1440px){
    .counternumber-body h2{
        font-size: 1.7rem;
        margin: 60px 0 28px 250px;
    }
    .counternumber-container{
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .counternumber-numberstitle{
        width: 350px;
        height: 200px;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .counternumber-numberstitle-numbers{
        display: flex;
        flex-direction: row;
        white-space: pre;
    }
    .counternumber-numberstitle-numbers p,
    .counternumber-numberstitle-title{
        font-size: 1.75rem;
        font-weight: bold;
    }
}

@media screen and (min-width: 1920px){
    .counternumber-body h2{
        font-size: 2rem;
        margin: 70px 0 30px 550px;
    }
    .counternumber-container{
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .counternumber-numberstitle{
        width: 400px;
        height: 250px;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .counternumber-numberstitle-numbers{
        display: flex;
        flex-direction: row;
        white-space: pre;
    }
    .counternumber-numberstitle-numbers p,
    .counternumber-numberstitle-title{
        font-size: 2rem;
        font-weight: bold;
    }
}