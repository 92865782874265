@media screen and (min-width: 320px){

    .pagecareer{
        height: 100%;
    }
    .header-pagecareer{
        height: 275px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .pagecareer-title h1{
        margin-top: 150px;
    }
    .body-pagecareer{
        background-color: #075787;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .body-pagecareer-container1{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px 0 50px 0;
    }
    .body-pagecareer-container1 h1{
        margin: 50px 0 75px 0;
        width: 90%;
        font-size: 1.5rem;
    }
    .body-pagecareer-container1-text{
        width: 90%;
        color: white;
        text-align: justify;
    }
    
    .body-pagecareer-container2{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px 0 75px 0;
    }
    .body-pagecareer-container2 h1{
        margin: 50px 0 75px 0;
        width: 75%;
        font-size: 1.5rem;
    }
    .body-pagecareer-container2-text{
        width: 90%;
        color: white;
        text-align: justify;
    }
    .body-pagecareer-container2-text-job{
        margin: 50px 0 50px 5px;
        width: 90%;
    }
    .body-pagecareer-container3{
        color: white;
        width: 80%;
    }
    .body-pagecareer-container3 p{
        margin-bottom: 7.5px;
    }
    .pagecareer-button{
        padding: 4px;
        font-size: 1rem;
        color: #075787;
        background-color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 1px 1px 1px black;
        text-decoration: none;
    }
    .pagecareer-button:hover{
        box-shadow: 2px 2px 2px black;
    }
    .body-pagecareer-container4{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .body-pagecareer-temoignage1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 30px 0 30px 0;
    }
    .body-pagecareer-temoignagesperso1{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px;
    }
    .body-pagecareer-temoignagesperso1 img{
        width: 100px;
        border-radius: 50%;
    }
    .body-pagecareer-temoignagesperso1 p{
        color: white;
        width: 80%;
        text-align: center;
        margin-top: 10px;
    }
    .body-pagecareer-temoignage2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 30px 0 30px 0;
    }
    .body-pagecareer-temoignagesperso2{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px;
    }
    .body-pagecareer-temoignagesperso2 img{
        width: 100px;
        border-radius: 50%;
    }
    .body-pagecareer-temoignagesperso2 p{
        color: white;
        width: 70%;
        text-align: center;
        margin-top: 10px;
    }
    .body-pagecareer-temoignage-text{
        color: white;
        width: 80%;
        text-align: center;
    }

}
@media screen and (min-width: 480px){

    .pagecareer{
        height: 100%;
    }
    .header-pagecareer{
        height: 275px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .pagecareer-title h1{
        margin-top: 150px;
    }
    .body-pagecareer{
        background-color: #075787;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .body-pagecareer-container1{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px 0 50px 0;
    }
    .body-pagecareer-container1 h1{
        margin: 50px 0 75px 0;
        width: 90%;
        font-size: 1.5rem;
    }
    .body-pagecareer-container1-text{
        width: 90%;
        color: white;
        text-align: justify;
    }
    
    .body-pagecareer-container2{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px 0 75px 0;
    }
    .body-pagecareer-container2 h1{
        margin: 50px 0 75px 0;
        width: 75%;
        font-size: 1.5rem;
    }
    .body-pagecareer-container2-text{
        width: 90%;
        color: white;
        text-align: justify;
    }
    .body-pagecareer-container2-text-job{
        margin: 50px 0 50px 20px;
        width: 80%;
    }
    .body-pagecareer-container3{
        color: white;
        width: 80%;
    }
    .body-pagecareer-container3 p{
        margin-bottom: 7.5px;
    }
    .pagecareer-button{
        padding: 4px;
        font-size: 1rem;
        color: #075787;
        background-color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 1px 1px 1px black;
        text-decoration: none;
    }
    .pagecareer-button:hover{
        box-shadow: 2px 2px 2px black;
    }
    .body-pagecareer-container4{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .body-pagecareer-temoignage1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 30px 0 30px 0;
    }
    .body-pagecareer-temoignagesperso1{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px;
    }
    .body-pagecareer-temoignagesperso1 img{
        width: 100px;
    }
    .body-pagecareer-temoignagesperso1 p{
        color: white;
        width: 70%;
        text-align: center;
        margin-top: 10px;
    }
    .body-pagecareer-temoignage2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 30px 0 30px 0;
    }
    .body-pagecareer-temoignagesperso2{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px;
    }
    .body-pagecareer-temoignagesperso2 img{
        width: 100px;
        border-radius: 50%;
    }
    .body-pagecareer-temoignagesperso2 p{
        color: white;
        width: 70%;
        text-align: center;
        margin-top: 10px;
    }
    .body-pagecareer-temoignage-text{
        color: white;
        width: 70%;
        text-align: center;
    }
}
@media screen and (min-width: 768px){

    .pagecareer{
        height: 100%;
    }
    .header-pagecareer{
        height: 275px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .pagecareer-title h1{
        margin-top: 150px;
    }
    .body-pagecareer{
        background-color: #075787;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .body-pagecareer-container1{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px 0 50px 0;
    }
    .body-pagecareer-container1 h1{
        margin: 50px 0 75px 0;
        width: 90%;
        font-size: 1.5rem;
    }
    .body-pagecareer-container1-text{
        width: 90%;
        color: white;
        text-align: justify;
        font-size: 1.1rem;
    }
    
    .body-pagecareer-container2{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px 0 75px 0;
    }
    .body-pagecareer-container2 h1{
        margin: 50px 0 75px 0;
        width: 75%;
        font-size: 1.5rem;
    }
    .body-pagecareer-container2-text{
        width: 90%;
        color: white;
        text-align: justify;
        font-size: 1.1rem;
    }
    .body-pagecareer-container2-text-job{
        margin: 50px 0 50px 20px;
        width: 80%;
    }
    .body-pagecareer-container3{
        color: white;
        width: 80%;
    }
    .body-pagecareer-container3 p{
        margin-bottom: 7.5px;
    }
    .pagecareer-button{
        padding: 4px;
        font-size: 1rem;
        color: #075787;
        background-color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 1px 1px 1px black;
        text-decoration: none;
    }
    .pagecareer-button:hover{
        box-shadow: 2px 2px 2px black;
    }
    .body-pagecareer-container4{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .body-pagecareer-temoignage1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 30px 0 30px 0;
    }
    .body-pagecareer-temoignagesperso1{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px;
    }
    .body-pagecareer-temoignagesperso1 img{
        width: 100px;
    }
    .body-pagecareer-temoignagesperso1 p{
        color: white;
        width: 70%;
        text-align: center;
        margin-top: 10px;
    }
    .body-pagecareer-temoignage2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 30px 0 30px 0;
    }
    .body-pagecareer-temoignagesperso2{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px;
    }
    .body-pagecareer-temoignagesperso2 img{
        width: 100px;
        border-radius: 50%;
    }
    .body-pagecareer-temoignagesperso2 p{
        color: white;
        width: 70%;
        text-align: center;
        margin-top: 10px;
    }
    .body-pagecareer-temoignage-text{
        color: white;
        width: 70%;
        text-align: center;
        font-size: 1.1rem;
    }
}

@media screen and (min-width: 1200px){

    .pagecareer{
        height: 100%;
    }
    .header-pagecareer{
        height: 275px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .pagecareer-title h1{
        margin-top: 150px;
    }
    .body-pagecareer{
        background-color: #075787;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .body-pagecareer-container1{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px 0 50px 0;
    }
    .body-pagecareer-container1 h1{
        margin: 50px 0 75px 0;
        width: 75%;
    }
    .body-pagecareer-container1-text{
        width: 90%;
        color: white;
        text-align: justify;
    }
    
    .body-pagecareer-container2{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px 0 75px 0;
    }
    .body-pagecareer-container2 h1{
        margin: 50px 0 75px 0;
        width: 75%;
    }
    .body-pagecareer-container2-text{
        width: 90%;
        color: white;
        text-align: justify;
    }
    .body-pagecareer-container2-text-job{
        margin: 50px 0 50px 20px;
        width: 75%;
    }
    .body-pagecareer-container3{
        color: white;
        width: 80%;
    }
    .body-pagecareer-container3 p{
        margin-bottom: 7.5px;
    }
    .pagecareer-button{
        padding: 4px;
        font-size: 1rem;
        color: #075787;
        background-color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 1px 1px 1px black;
        text-decoration: none;
    }
    .pagecareer-button:hover{
        box-shadow: 2px 2px 2px black;
    }
    .body-pagecareer-container4{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
    }
    .body-pagecareer-temoignage1{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 30px 0 30px 0;
    }
    .body-pagecareer-temoignagesperso1{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px;
    }
    .body-pagecareer-temoignagesperso1 img{
        width: 100px;
    }
    .body-pagecareer-temoignagesperso1 p{
        color: white;
        width: 70%;
        text-align: center;
        margin-top: 10px;
    }
    .body-pagecareer-temoignage2{
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        margin: 30px 0 30px 0;
    }
    .body-pagecareer-temoignagesperso2{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px;
    }
    .body-pagecareer-temoignagesperso2 img{
        width: 100px;
        border-radius: 50%;
    }
    .body-pagecareer-temoignagesperso2 p{
        color: white;
        width: 70%;
        text-align: center;
        margin-top: 10px;
    }
    .body-pagecareer-temoignage-text{
        color: white;
        width: 60%;
        text-align: justify;
    }

}

