@media screen and (min-width: 320px){
    .clientsslider-body{
        background-color: transparent;
        width: 100%;
        height: 580px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .clientsslider-logos{
        width: 100%;
    }
    .clientsslider-header{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
    }
    #clientsslider-titre{
        color: white;
        text-align: center;
        padding: 0 5px 0 5px;
        margin: 0 5px 0 5px;
    }
    #clientsslider-titre2{
        color: white;
        margin: 30px 0 15px 80px;
        font-size: 1.2rem;
    }
    .clientsslider-header-line{
        width: 35px;
        height: 2.5px;
        background-color: white;
        visibility: visible;
    }
    .clientsslider-display{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        z-index: 0;
    }
    .partnersslider-display{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        z-index: 0;
    }
    .img-clientsslider{
        margin: 10px 10px 10px 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        max-width: 185px;
        max-height: 90px;
        width: auto;
        height: auto;
        z-index: -1;
        background-color: white;
        opacity: 0.95;
        border-radius: 15px;
        padding: 10px;
        z-index: 1;
    }
    #white-client-logo{
        filter: brightness(1) invert(0);
    }
    #clientsslider-infos{
        color: white;
        text-align: center;
        margin-top: 25px;
        padding: 12px;
    }
    .clientsslider-marquee{
        margin: 0;
        overflow: hidden;
        position: relative;
        width: auto;
    }
    .partnersslider-marquee{
        margin: 0;
        overflow: hidden;
        position: relative;
        width: auto;
    }
}
@media screen and (min-width: 480px){
    .clientsslider-body{
        background-color: transparent;
        width: 100%;
        height: 730px;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .clientsslider-header{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
    }
    #clientsslider-titre{
        color: white;
        text-align: center;
        padding: 0 5px 0 5px;
        margin: 0 5px 0 5px;
    }
    #clientsslider-titre2{
        color: white;
        margin: 30px 0 15px 100px;
        font-size: 1.2rem;
    }
    .clientsslider-header-line{
        width: 100px;
        height: 3px;
        background-color: white;
        visibility: visible;
        margin: 0 10px 0 10px;
    }
    .clientsslider-display{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        z-index: 0;
    }
    .partnersslider-display{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        z-index: 0;
    }
    .img-clientsslider{
        margin: 15px 15px 15px 15px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        max-width: 210px;
        max-height: 100px;
        width: auto;
        height: auto;
        z-index: -1;
        background-color: white;
        opacity: 0.95;
        border-radius: 15px;
        padding: 10px;
        z-index: 1;
    }
    #white-client-logo{
        filter: brightness(0) invert(1);
    }
    #clientsslider-infos{
        color: white;
        text-align: center;
        margin: 0;
        padding: 15px;
    }
    .clientsslider-marquee{
        margin: 0;
        overflow: hidden;
        position: relative;
        width: auto;
    }
    .partnersslider-marquee{
        margin: 0;
        overflow: hidden;
        position: relative;
        width: auto;
    }
}
@media screen and (min-width: 768px){
    .clientsslider-body{
        background-color: transparent;
        width: 100%;
        height: 800px;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .clientsslider-header{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    #clientsslider-titre{
        color: white;
        text-align: center;
        font-size: 1.7rem;
        padding: 0 15px 0 15px;
        margin: 0 15px 0 15px;
    }
    #clientsslider-titre2{
        color: white;
        margin: 30px 0 15px 150px;
        font-size: 1.6rem;
    }
    .clientsslider-header-line{
        width: 160px;
        height: 3px;
        background-color: white;
        visibility: visible;
    }
    .clientsslider-display{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        z-index: 0;
    }
    .partnersslider-display{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        z-index: 0;
    }
    .img-clientsslider{
        margin: 20px 20px 20px 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        max-width: 320px;
        max-height: 130px;
        width: auto;
        height: auto;
        z-index: -1;
        background-color: white;
        opacity: 0.95;
        border-radius: 15px;
        padding: 10px;
        z-index: 1;
    }

    #white-client-logo{
        filter: brightness(1) invert(0);
    }

    #clientsslider-infos{
        color: white;
        text-align: center;
        margin: 0;
        padding: 20px;
        font-size: 1.5rem;
    }
    .clientsslider-marquee{
        margin: 0;
        overflow: hidden;
        position: relative;
        width: auto;
    }
    .partnersslider-marquee{
        margin: 0;
        overflow: hidden;
        position: relative;
        width: auto;
    }
}
@media screen and (min-width: 1024px){
    .clientsslider-body{
        background-color: transparent;
        width: 100%;
        height: 800px;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 0;
    }
    
    .clientsslider-header{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    #clientsslider-titre{
        color: white;
        text-align: center;
        font-size: 2rem;
        padding: 0 20px 0 20px;
        margin: 0 20px 0 20px;
    }
    #clientsslider-titre2{
        color: white;
        margin: 30px 0 15px 190px;
        font-size: 1.6rem;
    }
    .clientsslider-header-line{
        width: 240px;
        height: 3px;
        background-color: white;
        visibility: visible;
    }
    .clientsslider-display{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        z-index: 0;
    }
    .partnersslider-display{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        z-index: 0;
    }
    .img-clientsslider{
        margin: 20px 20px 20px 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        max-width: 320px;
        max-height: 128px;
        width: auto;
        height: auto;
        z-index: -1;
        background-color: white;
        opacity: 0.95;
        border-radius: 15px;
        padding: 10px;
        z-index: 1;
    }
    #white-client-logo{
        filter: brightness(1) invert(0);
    }
    #clientsslider-infos{
        color: white;
        text-align: center;
        margin: 10px 0;
        padding: 30px;
        font-size: 1.5rem;
    }
    .clientsslider-marquee{
        margin: 0;
        overflow: hidden;
        position: relative;
        width: auto;
    }
    .partnersslider-marquee{
        margin: 0;
        overflow: hidden;
        position: relative;
        width: auto;
    }
}
@media screen and (min-width: 1200px){
    .clientsslider-body{
        margin: 50px 0 50px 0;
        background-color: transparent;
        width: 100%;
        height: 650px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 0;
    }
    .clientsslider-header{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    #clientsslider-titre{
        color: white;
        text-align: center;
        font-size: 1.7rem;
        padding: 0 35px 0 35px;
        margin: 0 6px 0 6px;
    }
    #clientsslider-titre2{
        color: white;
        margin: 30px 0 15px 230px;
        font-size: 1.5rem;
    }
    .clientsslider-header-line{
        width: 400px;
        height: 2.5px;
        background-color: white;
        visibility: visible;
    }
    .clientsslider-display{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        z-index: 0;
    }
    .partnersslider-display{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        z-index: 0;
    }
    .img-clientsslider{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        max-width: 260px;
        max-height: 110px;
        width: auto;
        height: auto;
        z-index: -1;
        background-color: white;
        opacity: 0.95;
        border-radius: 15px;
        padding: 10px;
        z-index: 1;
    }
    #white-clientslider-logo{
        filter: brightness(1) invert(0);
    }
    #clientsslider-infos{
        color: white;
        text-align: center;
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
    }
    .clientsslider-marquee{
        margin: 0;
        overflow: hidden;
        position: relative;
        width: auto;
    }
    .partnersslider-marquee{
        margin: 0;
        overflow: hidden;
        position: relative;
        width: auto;
    }
}
@media screen and (min-width: 1440px){
    .clientsslider-body{
        background-color: transparent;
        width: 100%;
        height: 680px;
        margin-right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .clientsslider-header{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    #clientsslider-titre{
        color: white;
        text-align: center;
        font-size: 1.7rem;
        padding: 0 35px 0 35px;
        margin: 0 10px 0 10px;
    }
    #clientsslider-titre2{
        color: white;
        margin: 30px 0 15px 230px;
        font-size: 1.5rem;
    }
    .clientsslider-header-line{
        width: 480px;
        height: 2.5px;
        background-color: white;
        visibility: visible;
    }
    .clientsslider-display{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        z-index: 0;
    }
    .partnersslider-display{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        z-index: 0;
    }
    .img-clientsslider{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        max-width: 265px;
        max-height: 125px;
        width: auto;
        height: auto;
        z-index: -1;
        background-color: white;
        opacity: 0.95;
        border-radius: 15px;
        padding: 10px;
        z-index: 1;
    }
    #white-client-logo{
        filter: brightness(1) invert(0);
    }
    #clientsslider-infos{
        color: white;
        text-align: center;
        margin: 0;
        padding: 0;
        font-size: 1.3rem;
    }
    .clientsslider-marquee{
        margin: 0;
        overflow: hidden;
        position: relative;
        width: auto;
    }
    .partnersslider-marquee{
        margin: 0;
        overflow: hidden;
        position: relative;
        width: auto;
    }
}
@media screen and (min-width: 1920px){
    .clientsslider-body{
        background-color: transparent;
        width: 100%;
        height: 700px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .clientsslider-header{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    #clientsslider-titre{
        color: white;
        text-align: center;
        font-size: 2rem;
        padding: 0 35px 0 35px;
        margin: 0 10px 0 10px;
    }
    #clientsslider-titre2{
        color: white;
        margin: 30px 0 15px 300px;
        font-size: 1.5rem;
    }
    .clientsslider-header-line{
        width: 650px;
        height: 2.5px;
        background-color: white;
        visibility: visible;
    }
    .clientsslider-display{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        z-index: 0;
    }
    .partnersslider-display{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        z-index: 0;
    }
    .img-clientsslider{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        max-width: 300px;
        max-height: 140px;
        width: auto;
        height: auto;
        z-index: -1;
        background-color: white;
        opacity: 0.95;
        border-radius: 15px;
        margin: 20px 20px 20px 20px;
        padding: 10px;
        z-index: 1;
    }
    #white-client-logo{
        filter: brightness(1) invert(0);
    }
    #clientsslider-infos{
        color: white;
        text-align: center;
        margin: 0;
        padding: 0;
        font-size: 1.5rem;
    }
    .clientsslider-marquee{
        margin: 0;
        overflow: hidden;
        position: relative;
        width: auto;
    }
    .partnersslider-marquee{
        margin: 0;
        overflow: hidden;
        position: relative;
        width: auto;
    }
}