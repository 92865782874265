.footer-body-infos a{
    text-decoration: none;
    color: white;
}
.footer-footer-mentions-link{
    text-decoration: none;
    color: white;
}

@media screen and (min-width: 320px){
    #footer{
        background-color: #075787;
    }
    #footer-header{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer-header-loc{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }
    .footer-header-loc p{
        width: 60%;
        color: white;
    }
    #footer-header-loc-icon{
        height: 40px;
    }
    #logoCamondo{
        height: 55px;
        width: 160px;
        margin: 25px 0 25px 0;
    }
    #footer-map{
        border: 0;
        width: 250px;
        height: 150px;
    }
    #footer-body{
        margin: 60px 35px;
    }
    .footer-body-div{
        margin: 20px 0 30px 0;
    }
    .footer-body-line{
        height: 2px;
        width: 110px;
        background-color: white;
        margin: 2px 0 2px 0;
    }
    #footer-body-contact-titre{
        color: white;
        font-size: medium;
    }
    .footer-body-infos{
        color: white;
        margin: 7px 0 7px 0;
    }
    #footer-body-contact-infos{
        margin: 12px 0 12px 0;
    }
    .footer-body-infos-link{
        text-decoration: none;
        color: white;
        cursor: pointer;
    }
    #footer-logo{
        width: 30px;
        height: 30px;
    }
    #footer-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #footer-footer-mentions{
        color: white;
        text-align: center;
        font-size: smaller;
        padding: 20px;
    }
    #footer-logoC{
        height: 40px;
        width: 40px;
        margin: 25px 0 25px 0;
    }
    .footer-footer-mentions-links{
        text-decoration: none;
        color: white;
    }
}
@media screen and (min-width: 480px){
    #footer{
        background-color: #075787;
    }
    #footer-header{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer-header-loc{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }
    .footer-header-loc p{
        width: 52%;
        color: white;
    }
    #footer-header-loc-icon{
        height: 35px;
    }
    #logoCamondo{
        height: 55px;
        width: 170px;
        margin: 25px 0 25px 0;
    }
    #footer-map{
        border: 0;
        width: 320px;
        height: 200px;
    }
    #footer-body{
        margin: 60px 28px;
    }
    .footer-body-div{
        margin: 18px 0 28px 0;
    }
    .footer-body-line{
        height: 2px;
        width: 85px;
        background-color: white;
        margin: 2px 0 2px 0;
    }
    #footer-body-contact-titre{
        color: white;
        font-size: medium;
    }
    .footer-body-infos{
        color: white;
        margin: 6px 0 6px 0;
    }
    #footer-body-contact-infos{
        margin: 13px 0 13px 0;
    }
    .footer-body-infos-link{
        text-decoration: none;
        color: white;
    }
    #footer-logo{
        width: 28px;
        height: 28px;
    }
    #footer-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #footer-footer-mentions{
        color: white;
        text-align: center;
        font-size: smaller;
        padding: 20px;
    }
    #footer-logoC{
        height: 40px;
        width: 40px;
        margin: 25px 0 25px 0;
    }
    .footer-footer-mentions-links{
        text-decoration: none;
        color: white;
    }
}
@media screen and (min-width: 768px){
    #footer{
        background-color: #075787;
    }
    #footer-header{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer-header-loc{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }
    .footer-header-loc p{
        width: 53%;
        color: white;
    }
    #footer-header-loc-icon{
        height: 35px;
    }
    #logoCamondo{
        height: 70px;
        width: 220px;
        margin: 25px 0 25px 0;
    }
    #footer-map{
        border: 0;
        width: 475px;
        height: 250px;
    }
    #footer-body{
        margin: 60px 60px;
    }
    .footer-body-div{
        margin: 18px 0 28px 0;
    }
    .footer-body-line{
        height: 2px;
        width: 120px;
        background-color: white;
        margin: 2px 0 2px 0;
    }
    #footer-body-contact-titre{
        color: white;
        font-size: larger;
    }
    .footer-body-infos{
        color: white;
        margin: 6px 0 6px 0;
    }
    #footer-body-contact-infos{
        margin: 13px 0 13px 0;
        font-size: 1.2rem;
    }
    .footer-body-infos-link{
        text-decoration: none;
        color: white;
    }
    #footer-logo{
        width: 40px;
        height: 40px;
    }
    #footer-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #footer-footer-mentions{
        color: white;
        text-align: center;
        font-size: medium;
        padding: 20px;
    }
    #footer-logoC{
        height: 60px;
        width: 60px;
        margin: 25px 0 25px 0;
    }
    .footer-footer-mentions-links{
        text-decoration: none;
        color: white;
    }
}
@media screen and (min-width: 1024px){
    #footer{
        background-color: #075787;
    }
    #footer-header{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer-header-loc{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }
    .footer-header-loc p{
        width: 52%;
        color: white;
        font-size: large;
    }
    #footer-header-loc-icon{
        height: 36px;
    }
    #logoCamondo{
        height: 80px;
        width: 245px;
        margin: 25px 0 25px 0;
    }
    #footer-map{
        border: 0;
        width: 600px;
        height: 330px;
    }
    #footer-body{
        margin: 60px 90px;
    }
    .footer-body-div{
        margin: 18px 0 35px 0;
    }
    .footer-body-line{
        height: 2.5px;
        width: 130px;
        background-color: white;
        margin: 2px 0 2px 0;
    }
    #footer-body-contact-titre{
        color: white;
        font-size: 1.4rem;
    }
    .footer-body-infos{
        color: white;
        margin: 6px 0 6px 0;
    }
    #footer-body-contact-infos{
        margin: 13px 0 13px 0;
        font-size: 1.3rem;
    }
    .footer-body-infos-link{
        text-decoration: none;
        color: white;
    }
    #footer-logo{
        width: 40px;
        height: 40px;
    }
    #footer-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #footer-footer-mentions{
        color: white;
        text-align: center;
        font-size: medium;
        padding: 20px;
    }
    #footer-logoC{
        height: 70px;
        width: 70px;
        margin: 25px 0 25px 0;
    }
    .footer-footer-mentions-links{
        text-decoration: none;
        color: white;
    }
}
@media screen and (min-width: 1200px){
    #footer{
        background-color: #075787;
    }
    .footer-container{
        display: flex;
        flex-direction: row;
        margin: 0 0 28px 0;
    }
    #footer-header{
        display: flex;
        flex-direction: column;
        margin: 29px 28px 0 28px;
    }
    .footer-header-loc{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }
    .footer-header-loc p{
        width: 55%;
        color: white;
    }
    #footer-header-loc-icon{
        height: 30px;
    }
    #logoCamondo{
        height: 55px;
        width: 170px;
        margin: 22px 0 22px 0;
    }
    #footer-map{
        border: 0;
        width: 300px;
        height: 170px;
    }
    #footer-body{
        margin: 110px 0;
        display: flex;
        flex-direction: row;
    }
    .footer-body-div{
        margin: 0 30px 0 30px;
    }
    .footer-body-line{
        height: 2px;
        width: 85px;
        background-color: white;
        margin: 2px 0 2px 0;
    }
    #footer-body-contact-titre{
        color: white;
        font-size: medium;
        margin: 18px 0 12px 0;
    }
    .footer-body-infos{
        color: white;
        margin: 20px 0 20px 0;
    }
    #footer-body-contact-infos{
        margin: 12px 0 12px 0;
        font-size: 1.1rem;
    }
    .footer-body-infos-link{
        text-decoration: none;
        color: white;
    }
    #footer-logo{
        width: 30px;
        height: 30px;
    }
    #footer-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #footer-footer-mentions{
        color: white;
        text-align: center;
        font-size: smaller;
    }
    #footer-logoC{
        height: 35px;
        width: 35px;
        margin: 22px 0 22px 0;
    }
    .footer-footer-mentions-links{
        text-decoration: none;
        color: white;
    }
}
@media screen and (min-width: 1440px){
    #footer{
        background-color: #075787;
    }
    .footer-container{
        display: flex;
        flex-direction: row;
        margin: 0 0 28px 0;
    }
    #footer-header{
        display: flex;
        flex-direction: column;
        margin: 28px 28px 0 28px;
    }
    .footer-header-loc{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }
    .footer-header-loc p{
        width: 52%;
        color: white;
    }
    #footer-header-loc-icon{
        height: 35px;
    }
    #logoCamondo{
        height: 80px;
        width: 250px;
        margin: 22px 0 22px 0;
    }
    #footer-map{
        border: 0;
        width: 370px;
        height: 220px;
    }
    #footer-body{
        margin: 110px 0;
        display: flex;
        flex-direction: row;
    }
    .footer-body-div{
        margin: 0 45px 0 45px;
    }
    #footer-body-contact-titre{
        color: white;
        font-size: medium;
        margin: 50px 0 12px 0;
    }
    .footer-body-infos{
        color: white;
        margin: 20px 0 20px 0;
    }
    #footer-body-contact-infos{
        margin: 12px 0 12px 0;
        font-size: 1.1rem;
    }
    .footer-body-infos-link{
        text-decoration: none;
        color: white;
    }
    .footer-body-infos-link{
        text-decoration: none;
        color: white;
    }
    #footer-logo{
        width: 40px;
        height: 40px;
    }
    #footer-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #footer-footer-mentions{
        color: white;
        text-align: center;
        font-size: medium;
    }
    #footer-logoC{
        height: 50px;
        width: 50px;
        margin: 22px 0 22px 0;
    }
    .footer-footer-mentions-links{
        text-decoration: none;
        color: white;
    }
}
@media screen and (min-width: 1920px){
    #footer{
        background-color: #075787;
    }
    .footer-container{
        display: flex;
        flex-direction: row;
        margin: 0 0 30px 0;
    }
    #footer-header{
        display: flex;
        flex-direction: column;
        margin: 30px 30px 0 30px;
    }
    .footer-header-loc{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }
    .footer-header-loc p{
        width: 52%;
        color: white;
        font-size: larger;
    }
    #footer-header-loc-icon{
        height: 40px;
    }
    #logoCamondo{
        height: 85px;
        width: 265px;
        margin: 22px 0 22px 0;
    }
    #footer-map{
        border: 0;
        width: 550px;
        height: 300px;
    }
    #footer-body{
        margin: 140px 0;
        display: flex;
        flex-direction: row;
    }
    .footer-body-div{
        margin: 0 85px 0 85px;
    }
    .footer-body-line{
        height: 2.5px;
        width: 125px;
        background-color: white;
        margin: 2px 0 2px 0;
    }
    #footer-body-contact-titre{
        color: white;
        font-size: 1.5rem;
        margin: 50px 0 16px 0;
    }
    .footer-body-infos{
        color: white;
        margin: 1vh 0 1vh 0;
    }
    #footer-body-contact-infos{
        margin: 20px 0 20px 0;
        font-size: 1.4rem;
    }
    .footer-body-infos-link{
        text-decoration: none;
        color: white;
    }
    #footer-logo{
        width: 30px;
        height: 30px;
    }
    #footer-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #footer-footer-mentions{
        color: white;
        text-align: center;
        font-size: large;
    }
    #footer-logoC{
        height: 60px;
        width: 60px;
        margin: 22px 0 22px 0;
    }
    .footer-footer-mentions-links{
        text-decoration: none;
        color: white;
    }
}