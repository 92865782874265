.cards-expertises-link{
    text-decoration: none;
}

@media screen and (min-width: 320px){
    .expertises-body{
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-top: 100px;
    }
    .cards-expertises-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    #expertises-titre{
        color: white;
        margin: 30px 0 30px 35px;
    }
    .longcard-expertises-body{
        height: 100px;
        width: 90%;
        background-color: #075787;
        box-shadow: 1px 5px 5px black;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 30px 0;
    }
    .longcard-expertises-body h2{
        color: white;
    }
    .cards-expertises-logo-cloud{
        width: 70px;
        height: 70px;
        margin-right: 12px;
    }
}
@media screen and (min-width: 480px){
    .expertises-body{
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .cards-expertises-body{
        display: flex;
        flex-direction: column;
    }
    #expertises-titre{
        color: white;
        margin: 50px 0 30px 70px;
    }
    .longcard-expertises-body{
        height: 100px;
        width: 90%;
        background-color: #075787;
        box-shadow: 1px 5px 5px black;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 30px 0;
    }
    .longcard-expertises-body h2{
        color: white;
    }
}
@media screen and (min-width: 768px){
    .expertises-body{
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .cards-expertises-body{
        display: flex;
        flex-direction: column;
    }
    #expertises-titre{
        color: white;
        font-size: 2rem;
        margin: 30px 0 30px 170px;
    }
    .longcard-expertises-body{
        height: 100px;
        width: 90%;
        background-color: #075787;
        box-shadow: 1px 5px 5px black;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 30px 0;
    }
    .longcard-expertises-body h2{
        color: white;
    }
}
@media screen and (min-width: 1024px){
    .expertises-body{
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .cards-expertises-body{
        display: flex;
        flex-direction: column;
    }
    #expertises-titre{
        color: white;
        font-size: 2.5rem;
        margin: 30px 0 30px 300px;
    }
    .longcard-expertises-body{
        height: 100px;
        width: 90%;
        background-color: #075787;
        box-shadow: 1px 5px 5px black;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 30px 0;
    }
    .longcard-expertises-body h2{
        color: white;
    }
    .cards-expertises-logo-cloud{
        width: 70px;
        height: 70px;
        margin-right: 12px;
    }
}
@media screen and (min-width: 1200px){
    .expertises-body{
        background-color: transparent;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 80px;
        width: 100%;
    }
    .cards-expertises-body{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
    }
    #expertises-titre{
        color: white;
        font-size: 1.5rem;
        margin: 40px 0 30px 230px;
    }
    .longcard-expertises-body{
        height: 100px;
        width: 90%;
        background-color: #075787;
        box-shadow: 1px 5px 5px black;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 30px 0;
    }
    .longcard-expertises-body h2{
        color: white;
    }

    .header-cards-expertises-logo{
        width: 100px;
        margin: 400px -100px 0 -80px;
        z-index: 1;
    }
}
@media screen and (min-width: 1440px){
    .expertises-body{
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-top: 180px;
    }
    .cards-expertises-body{
        display: flex;
        flex-direction: row;
    }
    #expertises-titre{
        color: white;
        font-size: 1.5rem;
        margin: 55px 0 28px 250px;
    }
    .longcard-expertises-body{
        height: 100px;
        width: 90%;
        background-color: #075787;
        box-shadow: 1px 5px 5px black;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .longcard-expertises-body h2{
        color: white;
    }
}
@media screen and (min-width: 1920px){
    .expertises-body{
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    
    .cards-expertises-body{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    
    #expertises-titre{
        color: white;
        font-size: 2rem;
        margin: 70px 0 30px 550px;
    }
    .longcard-expertises-body{
        height: 100px;
        width: 90%;
        background-color: #075787;
        box-shadow: 1px 5px 5px black;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .longcard-expertises-body h2{
        color: white;
    }
}
