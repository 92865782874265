@media screen and (min-width: 320px){
    .cards-expertises{
        width: 275px;
        height: 300px;
        background-color: #075787;
        border-radius: 5px;
        white-space: break-spaces;
        box-shadow: 1px 5px 5px black;
        margin-bottom: 30px;
    }
    .cards-expertises-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 5px;
    }
    .cards-expertises-container1{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        height: 100px;
    }
    .cards-expertises-title{
        font-size: small;
        color: white;
        margin: 0 30px 0 15px;
        display: flex;
        align-items: center;
    }
    .cards-expertises-logo{
        width: 65px;
        height: 65px;
        margin-right: 11px;
    }
    .cards-expertises-texts{
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: medium;
        height: 150px;
        padding: 11px;
    }
    .cards-expertises-readmore{
        color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .cards-expertises-readmore p{
        font-weight: bold;
    }
}
@media screen and (min-width: 480px){
    .cards-expertises{
        width: 340px;
        height: 350px;
        background-color: #075787;
        border-radius: 5px;
        white-space: break-spaces;
        box-shadow: 1px 5px 5px black;
        margin-bottom: 30px;
    }
    .cards-expertises-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin: 5px;
    }
    .cards-expertises-container1{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30%;
        margin-top: 5px;
    }
    .cards-expertises-title{
        font-size: small;
        color: white;
        margin: 0 30px 0 15px;
        display: flex;
        align-items: center;
    }
    .cards-expertises-logo{
        width: 80px;
        height: 80px;
        margin-right: 11px;
    }
    .cards-expertises-texts{
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 50%;
        font-size: large;
    }
    .cards-expertises-readmore{
        color: white;
        display: flex;
        flex-direction: row;
    }
    .cards-expertises-readmore p{
        font-weight: bold;
    }
}
@media screen and (min-width: 768px){
    .cards-expertises{
        width: 450px;
        height: 500px;
        background-color: #075787;
        border-radius: 15px;
        white-space: break-spaces;
        box-shadow: 1px 5px 5px black;
        margin-bottom: 30px;
    }
    .cards-expertises-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin: 5px;
    }
    .cards-expertises-container1{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30%;
        margin: 0;
    }
    .cards-expertises-title{
        font-size: large;
        color: white;
        margin: 0 40px 0 20px;
        display: flex;
        align-items: center;
    }
    .cards-expertises-logo{
        width: 110px;
        height: 110px;
        margin-right: 30px;
    }
    .cards-expertises-texts{
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 50%;
        font-size: 1.5rem;
    }
    .cards-expertises-readmore{
        color: white;
        display: flex;
        flex-direction: row;
    }
    .cards-expertises-readmore p{
        font-weight: bold;
        font-size: 1.5rem;
    }
}
@media screen and (min-width: 1024px){
    .cards-expertises{
        width: 550px;
        height: 610px;
        background-color: #075787;
        border-radius: 15px;
        white-space: break-spaces;
        box-shadow: 1px 5px 5px black;
        margin-bottom: 30px;
    }
    .cards-expertises-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin: 5px;
    }
    .cards-expertises-container1{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30%;
        margin: 0;
    }
    .cards-expertises-title{
        font-size: 1.7rem;
        color: white;
        margin: 0 50px 0 35px;
        display: flex;
        align-items: center;
    }
    .cards-expertises-logo{
        width: 130px;
        height: 130px;
        margin-right: 30px;
    }
    .cards-expertises-texts{
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 50%;
        font-size: 1.8rem;
        margin: 5px;
    }
    .cards-expertises-readmore{
        color: white;
        display: flex;
        flex-direction: row;
    }
    .cards-expertises-readmore p{
        font-weight: bold;
        font-size: 2rem;
    }
}
@media screen and (min-width: 1200px){
    .cards-expertises{
        width: 280px;
        height: 300px;
        background-color: #075787;
        border-radius: 5px;
        white-space: break-spaces;
        box-shadow: 1px 5px 5px black;
        margin-bottom: 20px;
        position: relative;
        z-index: 5;
    }
    .cards-expertises:hover{
        cursor: pointer;
    }
    .cards-expertises-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 5px;
    }
    .cards-expertises-container1{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0;
        width: 100%;
    }
    .cards-expertises-title{
        font-size: medium;
        color: white;
        margin: 0 15px 0 25px;
        display: flex;
        text-align: left;
    }
    /*
    .cards-expertises-title{
        font-size: 1.25rem;
        width: 100%;
        color: white;
        margin: 0 30px 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }*/
    /*
    .cards-expertises-title h2{
        background-color: #075787;
        background-image: linear-gradient(40deg, #075787, #34d4ff);
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;
    }*/

    .cards-expertises-logo{
        width: 80px;
        height: 80px;
        margin-right: 12px;
    }
    .cards-expertises-texts{
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: medium;
        height: 50%;
    }
    .cards-expertises-readmore{
        color: #075787;
        display: flex;
        flex-direction: row;
        transform: translateY(15px);
        transition: 0.5s;
        opacity: 0;
       
    }
    .cards-expertises:hover .cards-expertises-readmore{
        transform: translateY(0px);
        opacity: 1;
    }
    .cards-expertises-readmore p{
        font-weight: bold;
        font-size: medium;
        color: white;
        /*
        background-color: #075787;
        background-image: linear-gradient(40deg, #075787, #37c1e7);
        background-size: 100%;
        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;*/
    }
}
@media screen and (min-width: 1440px){
    .cards-expertises{
        width: 340px;
        height: 360px;
        background-color: #075787;
        border-radius: 15px;
        white-space: break-spaces;
        box-shadow: 1px 5px 5px black;
        margin-bottom: 40px;
    }
    .cards-expertises-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin: 5px;
    }
    .cards-expertises-container1{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30%;
        margin: 0;
    }
    .cards-expertises-title{
        font-size: small;
        color: white;
        margin: 0 30px 0 15px;
        display: flex;
        align-items: center;
    }
    .cards-expertises-logo{
        width: 95px;
        height: 95px;
        margin-right: 18px;
    }
    .cards-expertises-texts{
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 50%;
        font-size: large;
    }
    .cards-expertises-readmore{
        color: white;
        display: flex;
        flex-direction: row;
        transform: translateY(15px);
        transition: 0.5s;
        opacity: 0;
    }
    .cards-expertises:hover .cards-expertises-readmore{
        transform: translateY(0px);
        opacity: 1;
    }
    .cards-expertises-readmore p{
        font-weight: bold;
        font-size: large;
    }
}
@media screen and (min-width: 1920px){
    .cards-expertises{
        width: 450px;
        height: 435px;
        background-color: #075787;
        border-radius: 15px;
        white-space: break-spaces;
        box-shadow: 1px 5px 5px black;
        margin-bottom: 40px;
    }
    .cards-expertises-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin: 15px;
    }
    .cards-expertises-container1{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30%;
        margin: 0;
    }
    .cards-expertises-title{
        font-size: larger;
        color: white;
        margin: 0 40px 0 35px;
        display: flex;
        align-items: center;
    }
    .cards-expertises-logo{
        width: 100px;
        height: 100px;
        margin-right: 20px;
    }
    .cards-expertises-texts{
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 50%;
        font-size: 1.4rem;
    }
    .cards-expertises-readmore{
        color: white;
        display: flex;
        flex-direction: row;
        transform: translateY(15px);
        transition: 0.5s;
        opacity: 0;
    }
    .cards-expertises:hover .cards-expertises-readmore{
        transform: translateY(0px);
        opacity: 1;
    }
    .cards-expertises-readmore p{
        font-weight: bold;
        font-size: 1.5rem;
    }
}