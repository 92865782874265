@media screen and (min-width: 320px){
    .cards-domaines-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .cards-domaines{
        width: 240px;
    }

    .cards-domaines-logo{
        width: 60px;
        height: 60px;
        margin-right: 20px;
    }

    .cards-domaines-texts{
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 70%;
        margin: 16px 0;
    }

    .cards-domaines-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin: 5px;
    }
    
    .cards-domaines-container1{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
    }

    .cards-domaines-title{
        color: white;
        margin-right: 25px;
        text-align: center;
    }

}
@media screen and (min-width: 480px){
    .cards-domaines-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .cards-domaines{
        width: 240px;
    }

    .cards-domaines-logo{
        width: 60px;
        height: 60px;
        margin-right: 20px;
    }

    .cards-domaines-texts{
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 70%;
        margin: 16px 0;
    }
    
    .cards-domaines-container1{
        margin: 0;
    }

    .cards-domaines-title{
        color: white;
        margin-right: 25px;
        text-align: center;
    }

}
@media screen and (min-width: 768px){
    .cards-domaines-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .cards-domaines{
        width: 300px;
    }

    .cards-domaines-logo{
        width: 100px;
        height: 100px;
        margin-right: 20px;
    }

    .cards-domaines-texts{
        font-size: 1.5rem;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 70%;
    }
    
    .cards-domaines-container1{
        margin: 0;
    }

    .cards-domaines-title{
        color: white;
        margin-right: 25px;
        font-size: large;
        text-align: center;
    }

}
@media screen and (min-width: 1024px){
    .cards-domaines-body{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .cards-domaines{
        width: 350px;
    }

    .cards-domaines-container{
        padding: 20px 0 20px 0;
    }

    .cards-domaines-logo{
        width: 100px;
        height: 100px;
        margin-right: 20px;
    }

    .cards-domaines-texts{
        width: 80%;
        height: 60%;
        font-size: 1.3rem;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 70%;
    }
    
    .cards-domaines-container1{
        margin: 0;
        height: 30%;
    }

    .cards-domaines-title{
        color: white;
        margin-right: 25px;
        font-size: large;
        text-align: center;
    }

}
@media screen and (min-width: 1200px){
    .cards-domaines-body{
        height: 1000px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .cards-domaines{
        width: 220px;
    }

    .cards-domaines-container{
        padding: 20px 0 20px 0;
    }

    .cards-domaines-logo{
        width: 57px;
        height: 57px;
        margin: 0;
    }

    .cards-domaines-texts{
        font-size: 0.85rem;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 70%;
    }
    
    .cards-domaines-container1{
        margin: 0;
        height: 30%;
    }

    .cards-domaines-title{
        color: white;
        margin-right: 25px;
        font-size: small;
        text-align: center;
    }

}
@media screen and (min-width: 1440px){
    .cards-domaines-body{
        height: 1000px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .cards-domaines{
        width: 230px;
    }

    .cards-domaines-container{
        padding: 20px 0 20px 0;
    }

    .cards-domaines-logo{
        width: 60px;
        height: 60px;
        margin: 0;
    }

    .cards-domaines-texts{
        font-size: 1rem;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 70%;
    }
    
    .cards-domaines-container1{
        margin: 0;
        height: 30%;
    }

    .cards-domaines-title{
        color: white;
        margin-right: 25px;
        font-size: small;
        text-align: center;
    }

}
@media screen and (min-width: 1920px){
    .cards-domaines-body{
        height: 1000px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .cards-domaines{
        width: 335px;
    }

    .cards-domaines-container{
        padding: 20px 0 20px 0;
    }

    .cards-domaines-logo{
        width: 90px;
        height: 90px;
        margin: 0;
    }

    .cards-domaines-texts{
        width: 80%;
        height: 60%;
        font-size: 1.3rem;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 70%;
    }
    
    .cards-domaines-container1{
        margin: 0;
        height: 30%;
    }

    .cards-domaines-title{
        color: white;
        margin-right: 25px;
        font-size: 1.2rem;
        text-align: center;
    }

}