.clear{
    clear: both;
    height: 20px;
}

.cardspagepractices-cardsbody-text-presse{
    font-size: calc(1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    color: white;
    padding: 30px 50px;
}

.form-practice{
    color: white;
}

@media screen and (min-width: 320px){

    .cardsanimation{
        animation: fade-In 1s;
    }
    @keyframes fade-In {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .cardspagepractices-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #075787;
    }

    .cardspagepractices-cards{
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cardspagepractices-cardsheader{
        margin-top: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .cardspagepractices-cardsheader img{
        width: 80px;
        height: 80px;
    }
    .cardspagepractices-cardsheader h1{
        color: white;
        font-size: 1.8rem;
        text-align: center;
        width: 100%;
    }
    .pagepractices-logo{
        margin: 15px 15px 15px 15px;
    }
    .cardspagepractices-cardsbody{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        color: white;
    }
    .cardspagepractices-cardsbody-img{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .cardspagepractices-cardsbody-img-img{
        width: 70px;
        height: 70px;
        margin: 0 0 25px 0;
    }
    .cardspagepractices-cardsbody-text{
        width: 100%;
        padding: 30px 30px;
        margin-top: 20px;
    }
    .cardspagepractices-cardsbody-text-presse{
        padding: 30px 35px;
        text-align: justify;
        font-size: calc(1.1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cardspagepractices-cardsbody-presse-text{
        width: 100%;
        padding: 15px 35px;
        text-align: justify;
        color: white;
        font-size: calc(1.1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cardspagepractices-cardsbody-presse-text ul li{
        margin: 15px 0;
    }
    .cardspagepractices-cardsbody-text-text{
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: 1.1rem;
    }
    .cardspagepractices-cardsbody-text-text ul{
        color: white;
        margin: 10px;
        text-align: justify;
    }
    .cardspagepractices-cardsbody-text p{
        text-align: justify;
        color: white;
        font-size: 1.1rem;
    }
    .cardspagepractices-cardsbody-text-text-warning{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cardspagepractices-cardsbody-text-text-warning img{
        width: 50px;
        height: 50px;
        margin: 0 0 20px 0;
    }
    .cardspagepractices-cardsbody-text-text-warning p{
        width: 80%;
    }
    .cardspagepractices-cardsbody-textlist{
        position: relative;
        color: white;
        margin: 20px 30px;
    }
    .cardspagepractices-cardsbody-textlist li{
        margin: 8px 0 8px 0;
        font-size: 1.1rem;
        text-align: justify;
    }
    .cardspagepractices-seperate{
        width: 300px;
        height: 1.5px;
        margin: 30px 0 15px 0;
        background-color: white;
    }

    .form-practice-presse{
        margin: 0 20px;
        text-align: justify;
        color: white;
        font-size: calc(1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
}


@media screen and (min-width: 480px){

    .cardsanimation{
        animation: fade-In 1s;
    }
    @keyframes fade-In {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .cardspagepractices-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #075787;
    }

    .cardspagepractices-cards{
        width: 92%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cardspagepractices-cardsheader{
        margin-top: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .cardspagepractices-cardsheader img{
        width: 90px;
        height: 90px;
    }
    .cardspagepractices-cardsheader h1{
        color: white;
        font-size: 1.8rem;
        text-align: center;
        width: 60%;
    }
    .pagepractices-logo{
        margin: 15px 15px 15px 15px;
    }
    .cardspagepractices-cardsbody{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }
    .cardspagepractices-cardsbody-text{
        width: 100%;
        padding: 30px 50px;
    }
    .cardspagepractices-cardsbody-text-presse{
        padding: 25px 35px;
    }
    .cardspagepractices-cardsbody-presse-text{
        width: 100%;
        padding: 25px 30px;
        color: white;
        font-size: calc(1.1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cardspagepractices-cardsbody-text-text{
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: 1.2rem;
    }
    .cardspagepractices-cardsbody-text-text ul{
        color: white;
        margin: 10px;
    }
    .cardspagepractices-cardsbody-text p{
        text-align: justify;
        color: white;
        font-size: 1.2rem;
    }
    .cardspagepractices-cardsbody-textlist{
        position: relative;
        color: white;
        margin: 20px 30px;
    }
    .cardspagepractices-cardsbody-textlist li{
        margin: 8px 0 8px 0;
        font-size: 1.2rem;
    }
    .cardspagepractices-seperate{
        width: 400px;
        height: 1px;
        margin: 30px 0 15px 0;
        background-color: white;
    }

    .form-practice-presse{
        margin: 0 10px;
        text-align: justify;
        color: white;
        font-size: calc(1.1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
}


@media screen and (min-width: 768px){

    .cardsanimation{
        animation: fade-In 1s;
    }
    @keyframes fade-In {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .cardspagepractices-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #075787;
    }

    .cardspagepractices-cards{
        width: 92%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cardspagepractices-cardsheader{
        margin-top: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .cardspagepractices-cardsheader img{
        width: 90px;
        height: 90px;
    }
    .cardspagepractices-cardsheader h1{
        color: white;
        font-size: 2.4rem;
        width: 70%;
    }
    .pagepractices-logo{
        margin: 20px 20px 20px 20px;
    }
    .cardspagepractices-cardsbody{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }
    .cardspagepractices-cardsbody-img{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .cardspagepractices-cardsbody-img-img{
        width: 90px;
        height: 90px;
        margin: 0 0 25px 0;
    }
    .cardspagepractices-cardsbody-text{
        width: 100%;
        padding: 30px 60px;
    }
    .cardspagepractices-cardsbody-text-presse{
        padding: 25px 25px;
    }
    .cardspagepractices-cardsbody-presse-text{
        padding: 25px;
    }
    .cardspagepractices-cardsbody-text-text{
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: 1.3rem;
    }
    .cardspagepractices-cardsbody-text-text ul{
        color: white;
        margin: 10px;
        text-align: justify;
    }
    .cardspagepractices-cardsbody-text p{
        text-align: justify;
        color: white;
        font-size: 1.3rem;
    }
    .cardspagepractices-cardsbody-text-text-warning{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .cardspagepractices-cardsbody-text-text-warning img{
        width: 50px;
        height: 50px;
        margin-right: 20px;
    }
    .cardspagepractices-cardsbody-text-text-warning p{
        width: 85%;
    }
    .cardspagepractices-cardsbody-textlist{
        position: relative;
        color: white;
        margin: 20px 30px;
    }
    .cardspagepractices-cardsbody-textlist li{
        margin: 8px 0 8px 0;
        font-size: 1.3rem;
    }
    .cardspagepractices-seperate{
        width: 400px;
        height: 1px;
        margin: 30px 0 15px 0;
        background-color: white;
    }
}

@media screen and (min-width: 1024px){

    .cardsanimation{
        animation: fade-In 1s;
    }
    @keyframes fade-In {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .cardspagepractices-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #075787;
    }

    .cardspagepractices-cards{
        width: 92%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cardspagepractices-cardsheader{
        margin-top: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .cardspagepractices-cardsheader img{
        width: 90px;
        height: 90px;
    }
    .cardspagepractices-cardsheader h1{
        color: white;
        font-size: 2.4rem;
        margin-left: 20px;
    }
    .pagepractices-logo{
        margin: 20px 20px 20px 20px;
    }
    .cardspagepractices-cardsbody{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }
    .cardspagepractices-cardsbody-text{
        width: 90%;
        padding: 25px 50px;
    }
    .cardspagepractices-cardsbody-text-presse{
        padding: 25px 50px;
        font-size: calc(1.2rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cardspagepractices-cardsbody-presse-text{
        width: 100%;
        padding: 25px 40px;
        font-size: calc(1.2rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cardspagepractices-cardsbody-presse-text ul li{
        font-size: calc(1.2rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        margin-left: 25px;
        margin: 20px;
    }
    .cardspagepractices-cardsbody-text-text{
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: 1.5rem;
    }
    .cardspagepractices-cardsbody-presse-text-text{
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: calc(1.2rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cardspagepractices-cardsbody-text-text ul{
        color: white;
        margin: 10px;
        text-align: justify;
    }
    .cardspagepractices-cardsbody-text p{
        text-align: justify;
        color: white;
        font-size: 1.5rem;
    }
    .cardspagepractices-cardsbody-text-text-warning{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .cardspagepractices-cardsbody-text-text-warning img{
        width: 50px;
        height: 50px;
        margin-right: 20px;
    }
    .cardspagepractices-cardsbody-text-text-warning p{
        width: 85%;
    }
    .cardspagepractices-cardsbody-textlist{
        position: relative;
        color: white;
        margin: 20px 30px;
    }
    .cardspagepractices-cardsbody-textlist li{
        margin: 8px 0 8px 0;
        font-size: 1.1rem;
    }
    .cardspagepractices-seperate{
        width: 400px;
        height: 1px;
        margin: 30px 0 15px 0;
        background-color: white;
    }

    .form-practice-presse{
        margin: 0 40px;
        text-align: justify;
        color: white;
        font-size: calc(1.2rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
}

@media screen and (min-width: 1200px){

    .cardsanimation{
        animation: fade-In 1s;
    }
    @keyframes fade-In {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .cardspagepractices-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #075787;

    }

    .cardspagepractices-cards{
        width: 92%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cardspagepractices-cardsheader{
        margin-top: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .cardspagepractices-cardsheader img{
        width: 90px;
        height: 90px;
    }
    .cardspagepractices-cardsheader h1{
        color: white;
        font-size: 2.4rem;
        margin-left: 20px;
        width: 30%;
    }
    .pagepractices-logo{
        margin: 20px 20px 20px 20px;
    }
    .cardspagepractices-cardsbody{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        font-size: 1.1rem;
    }
    .cardspagepractices-cardsbody-img{
        width: 150px;
    }
    .cardspagepractices-cardsbody-img-img{
        width: 75px;
        height: 75px;
    }
    .cardspagepractices-cardsbody-text{
        width: 1200px;
        padding: 25px 50px;
        display: flex;
        flex-direction: row;
    }
    .cardspagepractices-cardsbody-text-presse{
        font-size: calc(1.1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cardspagepractices-cardsbody-presse-text{
        width: 100%;
        padding: 25px 50px;
        display: flex;
        flex-direction: row;
        font-size: calc(1.1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cardspagepractices-cardsbody-text1{
        width: 1200px;
        padding: 25px 50px;
        display: flex;
        flex-direction: column;
    }
    .cardspagepractices-cardsbody-text-text{
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: calc(1.1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cardspagepractices-cardsbody-presse-text-text{
        display: flex;
        flex-direction: column;
        width: 90%;
        font-size: calc(1.1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cardspagepractices-cardsbody-text-text ul{
        color: white;
        margin: 10px;
    }
    .cardspagepractices-cardsbody-text p{
        text-align: justify;
        color: white;
        font-size: 1.1rem;
    }
    .cardspagepractices-cardsbody-text1 p{
        text-align: justify;
        color: white;
        font-size: 1.1rem;
    }
    .cardspagepractices-cardsbody-text-text-warning{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .cardspagepractices-cardsbody-text-text-warning img{
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }
    .cardspagepractices-cardsbody-text-text-warning p{
        width: 85%;
    }
    .cardspagepractices-cardsbody-textlist{
        position: relative;
        color: white;
        margin: 20px 30px;
    }
    .cardspagepractices-cardsbody-textlist li{
        margin: 8px 0 8px 0;
        font-size: 1.1rem;
    }
    .cardspagepractices-seperate{
        width: 400px;
        height: 1px;
        margin: 30px 0 15px 0;
        background-color: white;
    }
    .cardspagepractices-cardsbody-seperate{
        width: 1000px;
        height: 1px;
        margin: 30px 0 30px 0;
        background-color: white;
    }

    .form-practice-presse{
        margin: 0 50px;
        text-align: justify;
        color: white;
        font-size: calc(1.1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
}

@media screen and (min-width: 1440px){

    .cardsanimation{
        animation: fade-In 1s;
    }
    @keyframes fade-In {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .cardspagepractices-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #075787;
    }

    .cardspagepractices-cards{
        width: 92%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cardspagepractices-cardsheader{
        margin-top: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .cardspagepractices-cardsheader img{
        width: 100px;
        height: 100px;
    }
    .cardspagepractices-cardsheader h1{
        color: white;
        font-size: calc(1.75rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        margin: 0;
    }
    .pagepractices-logo{
        margin: 20px 20px 20px 20px;
    }
    .cardspagepractices-cardsbody{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        font-size: 1.2rem;
    }
    .cardspagepractices-cardsbody-img{
        width: 150px;
    }
    .cardspagepractices-cardsbody-img-img{
        width: 100px;
        height: 100px;
    }
    .cardspagepractices-cardsbody-text{
        width: 90%;
        padding: 25px 30px;
    }
    .cardspagepractices-cardsbody-presse-text{
        width: 100%;
        padding: 25px 50px;
        color: white;
        font-size: calc(1.1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cardspagepractices-cardsbody-text1{
        width: 90%;
        padding: 25px 30px;
    }
    .cardspagepractices-cardsbody-text-text{
        display: flex;
        flex-direction: column;
        width: 90%;
        font-size: 1.5rem;
    }
    .cardspagepractices-cardsbody-presse-text-text{
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: calc(1.1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cardspagepractices-cardsbody-text-text ul{
        color: white;
        margin: 10px;
    }
    .cardspagepractices-cardsbody-presse-text ul{
        color: white;
        margin-left: 25px;
    }
    .cardspagepractices-cardsbody-text-text ul li{
        font-size: 1.2rem;
    }
    .cardspagepractices-cardsbody-presse-text ul li{
        font-size: calc(1.1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
        margin-left: 25px;
        
    }
    .cardspagepractices-cardsbody-text p{
        text-align: justify;
        color: white;
        font-size: 1.2rem;
    }
    .cardspagepractices-cardsbody-text1 p{
        text-align: justify;
        color: white;
        font-size: 1.2rem;
    }
    .cardspagepractices-cardsbody-text-text-warning{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .cardspagepractices-cardsbody-text-text-warning img{
        width: 50px;
        height: 50px;
        margin-right: 20px;
    }
    .cardspagepractices-cardsbody-text-text-warning p{
        width: 85%;
    }
    .cardspagepractices-cardsbody-textlist{
        position: relative;
        color: white;
        margin: 20px 30px;
    }
    .cardspagepractices-cardsbody-textlist li{
        margin: 8px 0 8px 0;
        font-size: 1.2rem;
    }
    .cardspagepractices-seperate{
        width: 500px;
        height: 1px;
        margin: 50px 0 15px 0;
        background-color: white;
    }

    .form-practice-presse{
        margin-left: 50px;
        color: white;
        font-size: calc(1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
}

@media screen and (min-width: 1920px){

    .cardsanimation{
        animation: fade-In 1s;
    }
    @keyframes fade-In {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .cardspagepractices-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #075787;
    }

    .cardspagepractices-cards{
        width: 92%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .cardspagepractices-cardsheader{
        margin-top: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .cardspagepractices-cardsheader img{
        width: 125px;
        height: 125px;
    }
    .cardspagepractices-cardsheader h1{
        color: white;
        font-size: 2.5rem;
        margin: 0;
    }
    .pagepractices-logo{
        margin: 20px 20px 20px 20px;
    }
    .cardspagepractices-cardsbody{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        font-size: 1.3rem;
    }
    .cardspagepractices-cardsbody-text{
        width: 100%;
        padding: 25px 60px;
        font-size: 1.3rem;
    }
    .cardspagepractices-cardsbody-presse-text{
        width: 100%;
        padding: 25px 50px;
        color: white;
        font-size: calc(1.1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .cardspagepractices-cardsbody-text1{
        width: 100%;
        padding: 25px 60px;
        font-size: 1.3rem;
    }
    .cardspagepractices-cardsbody-text-text{
        display: flex;
        flex-direction: column;
        width: 90%;
        font-size: 1.3rem;
    }
    .cardspagepractices-cardsbody-text-text ul{
        color: white;
        margin: 10px;
    }
    .cardspagepractices-cardsbody-text p{
        text-align: justify;
        font-size: 1.3rem;
        color: white;
    }
    .cardspagepractices-cardsbody-text1 p{
        text-align: justify;
        font-size: 1.3rem;
        color: white;
    }
    .cardspagepractices-cardsbody-text-text-warning{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .cardspagepractices-cardsbody-text-text-warning img{
        width: 50px;
        height: 50px;
        margin-right: 20px;
    }
    .cardspagepractices-cardsbody-text-text-warning p{
        width: 85%;
    }
    .cardspagepractices-cardsbody-textlist{
        position: relative;
        color: white;
        margin: 20px 30px;
    }
    .cardspagepractices-cardsbody-textlist li{
        margin: 8px 0 8px 0;
        font-size: 1.3rem;
    }

    .cardspagepractices-seperate{
        width: 500px;
        height: 1px;
        margin: 50px 0 10px 0;
        background-color: white;
    }

    .form-practice-presse{
        margin-left: 50px;
        color: white;
        font-size: calc(1rem + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
}
