h1{
    color: white;
    text-align: center;
}

@media screen and (min-width: 320px){

    .container-pageexpertises{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .header-pageexpertises{
        height: 575px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .pageexpertises-headerlogo{
        margin: 20px;
        padding: 0;
        width: 115px;
        height: 115px;
    }
    .header-pageexpertises h1{
        margin: 20px 0 30px 0;
        font-size: 2rem;
    }
    .header-pageexpertises-text{
        width: 85%;
        padding: 25px 30px;
        margin: 0;
        text-align: center;
    }
    .header-pageexpertises-text p{
        width: 100%;
        color: white;
        font-size: 1.2rem;
    }

    .body-pageexpertises{
        background-color: #075787;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .body-pageexpertises-container{
        width: 85%;
        margin: 35px 0 35px 0;
    }
    .body-pageexpertises-container h2{
        color: white;
        margin: 0 0 20px 0;
    }
    .body-pageexpertises-container-enjeux{
        color: white;
        text-align: justify;
        font-size: 0.9rem;
    }
    .body-pageexpertises-container-domaines{
        color: white;
    }
    .text-bold{
        font-weight: bold;
    }
    .body-pageexpertises-container-text{
        display: flex;
        flex-direction: row;
    }
    .body-pageexpertises-seperate-RH{
        width: 250px;
        height: 1px;
        background-color: white;
        margin: 10px;
    }
    .body-pageexpertises-container-img{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .body-pageexpertises-container-img img{
        width: 360px;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
        pointer-events: none;
    }
    .body-pageexpertises-container fieldset{
        border: 2px solid white;
        padding: 25px;
    }
    .body-pageexpertises-container fieldset p{
        color: white;
        padding-bottom: 5px;
        font-size: 0.9rem;
        text-align: justify;
    }
    #body-pageexpertises-container-logo{
        width: 35px;
        margin: 0 5px 0 5px;
    }

}

@media screen and (min-width: 480px){

    .container-pageexpertises{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .header-pageexpertises{
        height: 525px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .pageexpertises-headerlogo{
        margin: 20px;
        padding: 0;
        width: 115px;
        height: 115px;
    }
    .header-pageexpertises h1{
        margin: 20px 0 30px 0;
        font-size: 2rem;
    }
    .header-pageexpertises-text{
        width: 85%;
        padding: 25px 30px;
        margin: 0;
        text-align: center;
    }
    .header-pageexpertises-text p{
        width: 100%;
        color: white;
        font-size: 1.2rem;
    }

    .body-pageexpertises{
        background-color: #075787;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .body-pageexpertises-container{
        width: 85%;
        margin: 35px 0 35px 0;
    }
    .body-pageexpertises-container h2{
        color: white;
        margin: 0 0 20px 0;
    }
    .body-pageexpertises-container-enjeux{
        color: white;
        text-align: justify;
        font-size: 1rem;
    }
    .body-pageexpertises-container-domaines{
        color: white;
    }
    .text-bold{
        font-weight: bold;
    }
    .body-pageexpertises-container-text{
        display: flex;
        flex-direction: row;
    }
    .body-pageexpertises-seperate-RH{
        width: 250px;
        height: 1px;
        background-color: white;
        margin: 10px;
    }
    .body-pageexpertises-container-img{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .body-pageexpertises-container-img img{
        width: 500px;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
        pointer-events: none;
    }
    .body-pageexpertises-container fieldset{
        border: 2px solid white;
        padding: 25px;
    }
    .body-pageexpertises-container fieldset p{
        color: white;
        padding-bottom: 5px;
        font-size: 1rem;
        text-align: justify;
    }
    #body-pageexpertises-container-logo{
        width: 35px;
        margin: 0 5px 0 5px;
    }
}

@media screen and (min-width: 768px){

    .container-pageexpertises{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .header-pageexpertises{
        height: 550px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .pageexpertises-logo {
        margin: 10px;
        width: 150px;
        height: 150px;
    }
    .header-pageexpertises h1{
        margin: 20px 0 30px 0;
        font-size: 2.5rem;
    }
    .header-pageexpertises-text{
        width: 70%;
        padding: 25px 30px;
        margin: 0;
        text-align: center;
    }
    .header-pageexpertises-text p{
        width: 100%;
        color: white;
        font-size: 1.25rem;
    }

    .body-pageexpertises{
        background-color: #075787;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .body-pageexpertises-container{
        width: 85%;
        margin: 35px 0 35px 0;
    }
    .body-pageexpertises-container h2{
        color: white;
        margin: 0 0 20px 0;
    }
    .body-pageexpertises-container-enjeux{
        color: white;
        text-align: justify;
        font-size: 1.1rem;
    }
    .body-pageexpertises-container-domaines{
        color: white;
    }
    .text-bold{
        font-weight: bold;
    }
    .body-pageexpertises-container-text{
        display: flex;
        flex-direction: row;
    }
    .body-pageexpertises-seperate-RH{
        width: 450px;
        height: 1px;
        background-color: white;
        margin: 10px;
    }
    .body-pageexpertises-container-img{
        display: flex;
        justify-content: center;
    }
    .body-pageexpertises-container-img img{
        width: 675px;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
        pointer-events: none;
    }
    .body-pageexpertises-container fieldset{
        border: 2px solid white;
        padding: 25px;
    }
    .body-pageexpertises-container fieldset p{
        color: white;
        padding-bottom: 5px;
        font-size: 1.1rem;
        text-align: justify;
    }
    #body-pageexpertises-container-logo{
        width: 35px;
        margin: 0 5px 0 5px;
    }
}

@media screen and (min-width: 1024px){

    .container-pageexpertises{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .header-pageexpertises{
        height: 550px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .pageexpertises-logo {
        margin: 10px;
        width: 140px;
        height: 140px;
    }
    .header-pageexpertises h1{
        margin: 20px 0 30px 0;
        font-size: 2.5rem;
    }
    .header-pageexpertises-text{
        width: 70%;
        padding: 25px 30px;
        margin: 0;
        text-align: center;
    }
    .header-pageexpertises-text p{
        width: 100%;
        color: white;
        font-size: 1.25rem;
    }

    .body-pageexpertises{
        background-color: #075787;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .body-pageexpertises-container{
        width: 85%;
        margin: 35px 0 35px 0;
    }
    .body-pageexpertises-container h2{
        color: white;
        margin: 0 0 20px 0;
    }
    .body-pageexpertises-container-enjeux{
        color: white;
        text-align: justify;
        font-size: 1.4rem;
    }
    .body-pageexpertises-container-domaines{
        color: white;
    }
    .text-bold{
        font-weight: bold;
    }
    .body-pageexpertises-container-text{
        display: flex;
        flex-direction: row;
    }
    .body-pageexpertises-seperate-RH{
        width: 650px;
        height: 1px;
        background-color: white;
        margin: 10px;
    }
    .body-pageexpertises-container-img{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .body-pageexpertises-container-img img{
        width: 850px;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
        pointer-events: none;
    }
    .body-pageexpertises-container fieldset{
        border: 2px solid white;
        padding: 25px;
    }
    .body-pageexpertises-container fieldset p{
        color: white;
        padding-bottom: 5px;
        font-size: 1.4rem;
        text-align: justify;
    }
    #body-pageexpertises-container-logo{
        width: 35px;
        margin: 0 5px 0 5px;
    }
}

@media screen and (min-width: 1200px){

    .container-pageexpertises{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .header-pageexpertises{
        height: 475px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .pageexpertises-logo {
        margin: 20px;
        width: 120px;
        height: 120px;
    }
    .header-pageexpertises h1{
        margin: 10px 0 20px 0;
        font-size: 2rem;
        text-align: center;
    }
    .header-pageexpertises-text{
        width: 70%;
        padding: 25px 30px;
        margin: 0;
        text-align: center;
    }
    .header-pageexpertises-text p{
        width: 100%;
        color: white;
        font-size: 1.25rem;
    }

    .body-pageexpertises{
        background-color: #075787;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .body-pageexpertises-container{
        width: 90%;
        margin: 35px 0 35px 0;
    }
    .body-pageexpertises-container h2{
        color: white;
        margin: 0 0 20px 0;
    }
    .body-pageexpertises-container-enjeux{
        color: white;
        font-size: 1.1rem;
    }
    .body-pageexpertises-container-domaines{
        color: white;
    }
    .text-bold{
        font-weight: bold;
    }
    .body-pageexpertises-container-text{
        display: flex;
        flex-direction: row;
    }
    .body-pageexpertises-seperate-RH{
        width: 400px;
        height: 1px;
        background-color: white;
        margin: 10px;
    }
    .body-pageexpertises-container-img{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .body-pageexpertises-container-img img{
        width: 600px;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
        pointer-events: none;
    }
    .body-pageexpertises-container fieldset{
        border: 2px solid white;
        padding: 25px;
    }
    .body-pageexpertises-container fieldset p{
        color: white;
        padding-bottom: 5px;
        font-size: 1.1rem;
    }
    #body-pageexpertises-container-logo{
        width: 35px;
        margin: 0 5px 0 5px;
    }
}

@media screen and (min-width: 1440px){

    .container-pageexpertises{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .header-pageexpertises{
        height: 650px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .pageexpertises-logo {
        margin: 10px;
        width: 170px;
        height: 170px;
    }
    .header-pageexpertises h1{
        margin: 20px 0 30px 0;
        font-size: 2.5rem;
    }
    .header-pageexpertises-text{
        width: 70%;
        padding: 25px 30px;
        margin: 0;
        text-align: center;
    }
    .header-pageexpertises-text p{
        width: 100%;
        color: white;
        font-size: 1.5rem;
    }

    .body-pageexpertises{
        background-color: #075787;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .body-pageexpertises-container{
        width: 90%;
        margin: 35px 0 35px 0;
    }
    .body-pageexpertises-container h2{
        color: white;
        margin: 0 0 20px 0;
        font-size: 2rem;
    }
    .body-pageexpertises-container-enjeux{
        color: white;
        font-size: 1.5rem;
    }
    .body-pageexpertises-container-domaines{
        color: white;
    }
    .text-bold{
        font-weight: bold;
    }
    .body-pageexpertises-container-text{
        display: flex;
        flex-direction: row;
    }
    .body-pageexpertises-seperate-RH{
        width: 800px;
        height: 1px;
        background-color: white;
        margin: 10px;
    }
    .body-pageexpertises-container-img{
        display: flex;
        justify-content: center;
    }
    .body-pageexpertises-container-img img{
        width: 700px;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
        pointer-events: none;
    }
    .body-pageexpertises-container fieldset{
        border: 2px solid white;
        padding: 25px;
    }
    .body-pageexpertises-container fieldset p{
        color: white;
        padding-bottom: 5px;
        font-size: 1.5rem;
    }
    #body-pageexpertises-container-logo{
        width: 35px;
        margin: 0 5px 0 5px;
    }
}

@media screen and (min-width: 1920px){

    .container-pageexpertises{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .header-pageexpertises{
        height: 650px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .pageexpertises-logo {
        margin: 10px;
        width: 180px;
        height: 180px;
    }
    .header-pageexpertises h1{
        margin: 20px 0 30px 0;
        font-size: 2.5rem;
    }
    .header-pageexpertises-text{
        width: 70%;
        padding: 25px 30px;
        margin: 0;
        text-align: center;
    }
    .header-pageexpertises-text p{
        width: 100%;
        color: white;
        font-size: 1.5rem;
    }

    .body-pageexpertises{
        background-color: #075787;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .body-pageexpertises-container{
        width: 90%;
        margin: 35px 0 35px 0;
    }
    .body-pageexpertises-container h2{
        color: white;
        margin: 0 0 20px 0;
        font-size: 2.5rem;
    }
    .body-pageexpertises-container-enjeux{
        color: white;
        font-size: 1.5rem;
    }
    .body-pageexpertises-container-domaines{
        color: white;
    }
    .text-bold{
        font-weight: bold;
    }
    .body-pageexpertises-container-text{
        display: flex;
        flex-direction: row;
    }
    .body-pageexpertises-seperate-RH{
        width: 800px;
        height: 1px;
        background-color: white;
        margin: 10px;
    }
    .body-pageexpertises-container-img{
        display: flex;
        justify-content: center;
    }
    .body-pageexpertises-container-img img{
        width: 900px;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
        pointer-events: none;
    }
    .body-pageexpertises-container fieldset{
        border: 2px solid white;
        padding: 25px;
    }
    .body-pageexpertises-container fieldset p{
        color: white;
        padding-bottom: 5px;
        font-size: 1.5rem;
    }
    #body-pageexpertises-container-logo{
        width: 35px;
        margin: 0 5px 0 5px;
    }

}