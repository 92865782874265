/*.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}*/

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  margin: 0;
  padding: 0;
  position: relative;
  background: url("../img/background/line_-_46956-_Original_.gif") repeat-x;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  background-color: black;
  width: 100%;
}

@media screen and (min-width: 320px){
  body:after{
    content:"";
    position: fixed; /* stretch a fixed position to the whole screen */
    top:0;
    height: 100vh;
    /*height: 800px; /* fix for mobile browser address bar appearing disappearing */
    left:0;
    right:0;
    z-index:-1; /* needed to keep in the background */
    background: url("../img/background/line_-_46956-_Original_.gif") center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: black;
  }
}
@media screen and (min-width: 480px){
  body:after{
    content:"";
    position: fixed; /* stretch a fixed position to the whole screen */
    top:0;
    height: 100vh;
    /*height: 850px; /* fix for mobile browser address bar appearing disappearing */
    left:0;
    right:0;
    z-index:-1; /* needed to keep in the background */
    background: url("../img/background/line_-_46956-_Original_.gif") center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: black;
  }
}

@media screen and (min-width: 768px){
  body:after{
    content:"";
    position: fixed; /* stretch a fixed position to the whole screen */
    top:0;
    height: 100vh;
    /*height: 1050px; /* fix for mobile browser address bar appearing disappearing */
    left:0;
    right:0;
    z-index:-1; /* needed to keep in the background */
    background: url("../img/background/line_-_46956-_Original_.gif") center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: black;
  }
}

@media screen and (min-width: 1024px){
  body:after{
    content:"";
    position: fixed; /* stretch a fixed position to the whole screen */
    top:0;
    height: 100vh;
    /*height: 1400px; /* fix for mobile browser address bar appearing disappearing */
    left:0;
    right:0;
    z-index:-1; /* needed to keep in the background */
    background: url("../img/background/line_-_46956-_Original_.gif") center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: black;
  }
}

@media screen and (min-width: 1200px){
  body:after{
    content:"";
    position: fixed; /* stretch a fixed position to the whole screen */
    top:0;
    height: 100vh;
    /*height: 600px; /* fix for mobile browser address bar appearing disappearing */
    left:0;
    right:0;
    z-index:-1; /* needed to keep in the background */
    background: url("../img/background/line_-_46956-_Original_.gif") center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: black;
  }
}

@media screen and (min-width: 1440px){
  body:after{
    content:"";
    position: fixed; /* stretch a fixed position to the whole screen */
    top:0;
    height: 100vh;
    /*height: 900px; /* fix for mobile browser address bar appearing disappearing */
    left:0;
    right:0;
    z-index:-1; /* needed to keep in the background */
    background: url("../img/background/line_-_46956-_Original_.gif") center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: black;
  }
}

@media screen and (min-width: 1920px){
  body:after{
    content:"";
    position: fixed; /* stretch a fixed position to the whole screen */
    top:0;
    height: 100vh;
    /*height: 1000px; /* fix for mobile browser address bar appearing disappearing */
    left:0;
    right:0;
    z-index:-1; /* needed to keep in the background */
    background: url("../img/background/line_-_46956-_Original_.gif") center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: black;
  }
}